<template>
<div class="container1150">
<header><strong>无机透水混凝土路面</strong></header>
<main>
<p>
                陶瓷透水砖为纯色系列，免烧结成型，表面致密，面层通过添加超亲水添加剂实现快速透水，具有透水、滤水等功能；主要应用于城市道路、停车场及公园道路等非机动车道
            </p>
<div class="img1"></div>
<h2>产品特性</h2>
<p>● · 透水性能好，透水率高达1.5-3.5mm/s，透水性能维持时间长</p>
<p>
                ● ·
                材料全部采用无机材料制成，环保安全，无毒无害（添加无毒的监测报告，满足最严格的校园操场要求）
            </p>
<div class="img-list">
<div class="img1"></div>
<div class="img2"></div>
</div>
<p>
                ● ·
                根据不同承重要求可广泛应用于各种道路、广场、公园、停车场等。本产品抗压强度可达到C40，抗弯强度可达6.4MPa
            </p>
<p>· 轻载路面：</p>
<div class="img2"></div>
<p>· 重载路面：</p>
<div class="img3"></div>
<div class="img4"></div>

</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 550px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/5-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img2 {
            width: 1100px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/product/5-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1100px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/product/5-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/5-6.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>